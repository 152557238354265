<template>
  <div class="about-company">
    <v-form
      class="about-company-form"
      ref="form"
      @submit.prevent="handleFormSubmit"
      v-model="valid"
    >
      <div class="form-body">
        <section>
          <label class="section-label">Dialog</label>

          <rcc-text-input
            type="text"
            label="Логин на портале X5"
            v-model="form.x5_login"
            width="500px"
          />

          <rcc-text-input
            label="Пароль на портале X5"
            type="text"
            v-model="form.x5_pass"
            width="500px"
          />
        </section>
      </div>
    <rcc-footer-buttons :is-submit="isSubmit" cancel-text="" />
    </v-form>
  </div>
</template>

<script>
import RccTextInput from 'Components/controls/rcc-text-input'
import RccFooterButtons from 'Components/layouts/rcc-footer-buttons'

import Form from '@/mixins/form'
import Page from '@/mixins/page'
export default {
  name: 'RccAboutCompany',

  components: {
    RccTextInput,
    RccFooterButtons
  },

  mixins: [Page, Form],

  data() {
    return {
      form: {
        x5_login: null,
        x5_pass: null
      }
    }
  },

  mounted() {
    this.$apiMethods.supplierProfile.get()
      .then(data => {
        this.form = data
      })
  },

  methods: {
    handleFormSubmit() {
      this.isSubmit = true

      this.$apiMethods.supplierProfile.update(this.form)
        .then(() => {
          window.snackbar('Информация успешно изменена', 'info')
        })
        .finally(() => { this.afterSubmit() })
    }
  }
}
</script>

<style lang="scss" scoped>
.about-company {
  height: 100%;

  .about-company-form {
    height: 100%;
    display: flex;
    flex-direction: column;

    .form-body{
      flex-grow: 1;
      padding: $base-form-padding;
    }

    .section-label {
      display: inline-block;
      color: #5f6972;
      font-size: 16px;
      margin-bottom: 20px;
      font-weight: 800;
    }
  }
}
</style>
